<template>  
    <template v-if="!(!configurableFieldExists && hideByDefault) || configurableFieldExists && configurableFieldIsVisible(sharedObj.visibleFieldsObject, fieldName) || forceVisibility"> 
        <template v-if="showItem && !props.groupHeader && !props.useFullRowForValue">
            <div :title="props.title" class="d-flex justify-content-between border-bottom bg-white">
                <span style="width:40%;" class="ps-2 border-end text-muted" >
                    {{props.caption}} 
                    <template v-if="sharedObj.visibleFieldsObject && fieldName && configurableFieldIsMandatory(sharedObj.visibleFieldsObject, fieldName)">*</template>
                    <template v-if="isRequired && !isBoolean()">*</template>
                </span>
                <span style="width:60%" :class="{'text-center':isBoolean()}">
                    <slot>
                        <template v-if="isBoolean()">
                            <input class="form-check-input fs-6 m-0 mt-1" :title="props.title ?? props.caption" type="checkbox" v-model='dataObject.current[props.fieldName]'>
                        </template>
                        <template v-if="isDateTime()">
                            <ODatePicker class="border-0 w-100" :title="props.title" v-model='dataObject.current[props.fieldName]' show-input-icon date format="Short Date" />
                        </template>
                    </slot> 
                </span>
            </div>
        </template>
    </template>
</template>

<script setup >
    import { ref, inject, watch, computed } from 'vue';
    import { propertiesGridKey } from 'o365.modules.vue.injectionKeys.js';
    import { configurableFieldIsVisible, configurableFieldIsMandatory } from "o365.vue.components.PropertiesItem.ConfigurableFields.ts";
    import ODatePicker from 'o365.vue.components.DatePicker.vue';

    const sharedObj = inject(propertiesGridKey, null);

    const dataObject = sharedObj.value.dataObject;

    const show = ref(true);

    const showItem = computed(() => {
        if(sharedObj.value.withValues && !dataObject.current[props.fieldName]){
            return false
        }if(show.value && isEmpty.value){
            return false;
        } else if(show.value && !isEmpty.value){
            return true;
        } else if(!show.value){
            return false;
        } else {
            return true;
        }
    })

    watch(() => sharedObj.value.propFilter, (newVal) => {
        if(!newVal){
            show.value = true;
        }
        show.value = props.caption && props.caption.toLowerCase().includes(newVal.toLowerCase()) 
    });

    const isEmpty = ref(false);
    watch(() => sharedObj.value.withValues, (newVal) => {
        if(newVal && dataObject && props.fieldName){
            if(!dataObject.current[props.fieldName] && show.value){
                isEmpty.value = true;
            } else {
                isEmpty.value = false;
            }
        } else {
            isEmpty.value = false;
        }
    });    

    const isRequired = computed(() =>{
        return dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName).nullable === false || props.required === true;
    });

    function isBoolean(){
        return dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName).dataType === 'bit';
    };

    function isDateTime(){
        return dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName).dataType === 'datetime' || dataObject.getFields(props.fieldName) && dataObject.getFields(props.fieldName).dataType === 'date' || dataObject.getFields(props.fieldName).dataType === 'datetime2'  ;
    };

    const configurableFieldExists = computed(()=>{
        if(sharedObj.value.visibleFieldsObject){
            const index = sharedObj.value.visibleFieldsObject.data.findIndex(f => f.FieldName === props.fieldName);
            return index !== -1;
        }else{
            return true;
        }
    });

    const props = defineProps({
        caption:String,
        groupHeader:String,
        useFullRowForValue:Boolean,
        fieldName:String,
        forceVisibility:Boolean,
        title:String,
        required: Boolean,
        hideByDefault: {
            type: Boolean,
            default: false,
        }
    }); 
</script>